@import 'node_modules/wonderland/src/utilities';
@import "~video.js/dist/video-js.min.css";

.lazy {
  &__loaded {
    animation: blur .5s linear;
  }
}

.normalize {
  &-all {
    > * {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &-heading {
    > h1, > h2, > h3, > h4, > h5, > h6 {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &-body {
    > span, > div, > p {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

}

.leaflet {
  &-popup {
    margin-bottom: 32px !important;

    &-content {
      margin: 0 !important;
      width: auto !important;

      &-wrapper {
        border-radius: 0 !important;
        padding: 0 !important;

      }

      &-close-button {
        color: #fff;
      }
    }
  }
}

.elevator {
  img {
    transition: all .5s ease-in-out;
  }

  &:hover {
    img {
      transition: all .5s ease-in-out;
      transform: translateY(-.5rem);
    }
  }
}

.zoom-in {
  img {
    transition: all .5s ease-in-out;
    transform: scale(1);
  }

  &:hover {
    img {
      transition: all .5s ease-in-out;
      transform: scale(1.1);
    }
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  animation: blur .5s linear;
}

.vueperslides {
  &__parallax-wrapper {
    &:before {
      box-shadow: unset !important;
    }

    &:after {
      box-shadow: unset !important;
    }
  }

  &__bullet {
    .default {
      border: 2px solid map-get($palette, 'blue-300') !important;
      box-shadow: unset !important;
    }

    &--active {
      .default {
        border: 6px solid map-get($palette, 'blue-300') !important;
      }
    }
  }
}

.vjs-poster {
  background-size: cover;
}

.vjs-big-play-button {
  display: none;
  visibility: hidden;
}

.vm--overlay {
  background: rgba(0, 0, 0, .8) !important;
}
